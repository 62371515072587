<template>
  <div class="grid grid-cols-1 gap-6 sm:mt-16 sm:grid-cols-2 animated reveal">
    <div class="col-span-1 pt-8 sm:pt-16">
      <div class="text-4.5xl font-bold text-invest-primary">Welcome back!</div>
      <p class="mt-5 text-base text-invest-primary text-opacity-80">
        Sign in to continue.
      </p>
    </div>
    <div class="flex justify-end col-span-1">
      <div class="card">
        <alert variant="error" v-if="loginForm.error">
          {{ loginForm.error }}
        </alert>
        <form @submit.prevent="login">
          <form-input
            height="h-16"
            type="email"
            name="email"
            :form="loginForm"
            v-model="loginForm.data.email.value"
          >
            Email Address
          </form-input>

          <form-input
            height="h-16"
            type="password"
            name="password"
            :form="loginForm"
            v-model="loginForm.data.password.value"
          >
            Password
          </form-input>
          <div class="flex justify-between mt-4">
            <div></div>
            <div
              class="text-xs font-semibold cursor-pointer text-invest-purple"
              @click="navigateToForgotPassword"
            >
              Forgot Password?
            </div>
          </div>
          <div class="mt-12">
            <button
              class="btn btn-primary"
              type="submit"
              :disabled="loginForm.loading"
            >
              <loader v-if="loginForm.loading" />
              <span v-else>Sign in</span>
            </button>
          </div>
        </form>
        <div class="mt-8 text-xs text-center text-invest-dark">
          Don’t have an account?
          <span
            class="font-semibold cursor-pointer text-invest-purple"
            @click="navigateToSignUp"
            >Sign Up</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, SetupContext } from 'vue';
import authModule from '@/modules/auth/authModule';

export default defineComponent({
  emits: ['navigate'],
  setup: (_props, { emit }: SetupContext) => {
    const { loginForm, login } = authModule();

    const navigateToSignUp = () => emit('navigate', 'register');

    const navigateToForgotPassword = () => emit('navigate', 'forgot-password');

    return {
      loginForm,
      navigateToSignUp,
      login,
      navigateToForgotPassword,
    };
  },
});
</script>
