
import { defineComponent, SetupContext } from 'vue';
import authModule from '@/modules/auth/authModule';

export default defineComponent({
  emits: ['navigate'],
  setup: (_props, { emit }: SetupContext) => {
    const { loginForm, login } = authModule();

    const navigateToSignUp = () => emit('navigate', 'register');

    const navigateToForgotPassword = () => emit('navigate', 'forgot-password');

    return {
      loginForm,
      navigateToSignUp,
      login,
      navigateToForgotPassword,
    };
  },
});
