import { reactive, toRefs } from 'vue';
import resource from '@/modules/resource';
import { useRouter } from 'vue-router';
import { loginRequest } from '../requests';
import validation from '../validation';
import { AUTH_TOKEN } from '@/constants';
import { useStore } from '@/store';

export default function loginResource() {
  const { basicForm, mapFormErrors } = resource();
  const { validateForm } = validation();

  const { dispatch } = useStore();
  const { push } = useRouter();

  const data = reactive({
    loginForm: basicForm([
      {
        name: 'email',
        rules: 'required|email',
      },
      {
        name: 'password',
        rules: 'required',
      },
    ]),
    registerForm: basicForm([
      {
        name: 'first_name',
        rules: 'required',
      },
      {
        name: 'last_name',
        rules: 'required',
      },
      {
        name: 'email',
        rules: 'required|email',
      },
      {
        name: 'mobile',
        rules: 'required|phone',
      },
      {
        name: 'password',
        rules: 'required|alphaNum',
      },
    ]),
  });

  const login = () => {
    if (!validateForm(data.loginForm)) return false;
    data.loginForm.loading = true;
    loginRequest(
      data.loginForm.data.email.value,
      data.loginForm.data.password.value,
    )
      .then(({ data }) => {
        const { token, user }: any = data;
        window.localStorage.setItem(AUTH_TOKEN, token);
        dispatch('login', { user, token }).then(() => push('/dashboard'));
      })
      .catch((error) => {
        data.loginForm.error = error?.response?.data?.message;
        mapFormErrors(data.loginForm, error?.response?.data?.errors);
      })
      .finally(() => (data.loginForm.loading = false));
  };

  return { ...toRefs(data), login };
}
